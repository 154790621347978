import z from "zod";

import type { Prettify } from "~/utils/helpers";

export const frontendFilterOperator = z.enum([
  "contains",
  "notContains",
  "equals",
  "notEquals",
  "greaterThan",
  "greaterThanOrEqual",
  "lessThan",
  "lessThanOrEqual",
  "startsWith",
  "endsWith",
  "notStartsWith",
  "betweenInclusive",
  "inList",
  "flagHas",
  "flagNotHas",
]);
export type FrontendFilterOperator = z.infer<typeof frontendFilterOperator>;
export const backendFilterOperator = z.enum([
  "contains",
  "notContains",
  "equals",
  "notEquals",
  "greaterThan",
  "greaterThanOrEqual",
  "lessThan",
  "lessThanOrEqual",
  "startsWith",
  "endsWith",
  "notStartsWith",
  "betweenInclusive",
  "inList",
]);
export type FilterOperator = z.infer<typeof backendFilterOperator>;

export const frontendQueryFilterSchema = z.object({
  field: z.string(),
  value: z.array(z.union([z.string(), z.boolean(), z.number()])),
  operator: frontendFilterOperator,
});
export type FrontendQueryFilter = z.infer<typeof frontendQueryFilterSchema>;
export const queryFilterSchema = z.object({
  field: z.string(),
  value: z.array(z.union([z.string(), z.boolean(), z.number()])),
  operator: backendFilterOperator,
});
export type QueryFilter = z.infer<typeof queryFilterSchema>;

export enum FilterOperatorBackend {
  Contains = 0,
  NotContains = 1,
  Equals = 2,
  NotEquals = 3,
  GreaterThan = 4,
  GreaterThanOrEqual = 5,
  LessThan = 6,
  LessThanOrEqual = 7,
  StartsWith = 8,
  EndsWith = 9,
  NotStartsWith = 10,
  BetweenInclusive = 11,
}

export type FilterGroup = {
  operator: FilterOperatorBackend;
  value: string | boolean | number;
};

export type FilterBody = {
  filterGroups: Record<string, FilterGroup[]>;
};

export const filterSchema = z.object({
  key: z.string(),
  text: z.string(),
  type: z.custom<FilterType>(),
  operator: z.custom<FilterOperator>().optional(),
  values: z.array(z.string()).optional(),
});
export type FilterSchema = z.infer<typeof filterSchema>;

export const groupFilterSchema = z.object({
  id: z.string(),
  name: z.string(),
  field: z.string(),
  operations: z.array(
    z.object({
      operator: z.custom<FilterOperator>(),
      values: z.array(z.string()),
    })
  ),
});
export const groupFiltersSchema = z.object({
  id: z.string(),
  name: z.string(),
  fieldFilters: z.array(groupFilterSchema),
});

export const filterIcon = new Map([
  ["id", "mdi-numeric-1-box-outline"],
  ["status", "mdi-progress-check"],
  ["flag", "mdi-flag"],
  ["assignedTo", "mdi-account"],
  ["library", "mdi-bookshelf"],
  ["rule", "mdi-clipboard-text-search-outline"],
  ["category", "mdi-format-list-bulleted"],
  ["createdAt", "mdi-calendar-check"],
  ["dueDate", "mdi-calendar-question"],
  ["latestStatusChanged", "mdi-calendar-check"],
  ["latestScreening", "mdi-calendar-clock"],
  ["latestScreeningRange", "mdi-calendar-range"],
  ["SailDate", "mdi-ship-wheel"],
  ["POL", "mdi-map-marker"],
  ["POD", "mdi-map-marker"],
  ["statusMetadata", "mdi-file-document-outline"],
  ["BookingOffice", "mdi-book-alphabet"],
]);

export type BaseFilterConfig = {
  key: string;
  label: string;
  availableOperators: FrontendFilterOperator[];
  icon?: string;
};
export type EnumFilterConfig = BaseFilterConfig & {
  type: "enum";
  values: string[];
};
export type FlagFilterConfig = BaseFilterConfig & {
  type: "flag";
  values: string[];
};
export type DateFilterConfig = BaseFilterConfig & {
  type: "date" | "dateRange";
};
export type LibraryFilterConfig = BaseFilterConfig & { type: "library" };
export type StringFilterConfig = BaseFilterConfig & { type: "string" };
export type ReasonFilterConfig = BaseFilterConfig & { type: "reason" };
export type StatusFilterConfig = BaseFilterConfig & { type: "status" };
export type UserFilterConfig = BaseFilterConfig & { type: "user" };
export type BooleanFilterConfig = BaseFilterConfig & { type: "boolean" };
export type NumberFilterConfig = BaseFilterConfig & { type: "number" };
export type FilterConfig = Prettify<
  | EnumFilterConfig
  | FlagFilterConfig
  | StringFilterConfig
  | NumberFilterConfig
  | DateFilterConfig
  | UserFilterConfig
  | ReasonFilterConfig
  | StatusFilterConfig
  | BooleanFilterConfig
  | LibraryFilterConfig
>;

export type FilterType = FilterConfig["type"];

export const typeToValidOperators: Record<
  FilterConfig["type"],
  FrontendFilterOperator[]
> = {
  number: [
    "equals",
    "notEquals",
    "greaterThan",
    "lessThan",
    "greaterThanOrEqual",
    "lessThanOrEqual",
    "betweenInclusive",
  ],
  date: ["equals", "notEquals", "greaterThan", "lessThan", "betweenInclusive"],
  boolean: ["equals", "notEquals"],
  enum: ["equals", "notEquals"],
  flag: ["flagHas", "flagNotHas"],
  string: ["equals", "notEquals", "contains", "startsWith", "endsWith"],
  library: ["equals", "notEquals"],
  status: ["equals", "notEquals"],
  reason: ["equals", "notEquals"],
  dateRange: ["betweenInclusive"],
  user: ["equals", "notEquals", "inList"],
};

export type FilterConfigResponse = {
  filters: FilterConfig[];
  defaultFilterKey: string;
  defaultFilterValue: string;
  defaultFilter: QueryFilter | null;
};

export const getFilterBodyOperatorEnum = (
  op: FilterOperator
): FilterOperatorBackend => {
  switch (op) {
    case "contains":
      return FilterOperatorBackend.Contains;
    case "notContains":
      return FilterOperatorBackend.NotContains;
    case "equals":
      return FilterOperatorBackend.Equals;
    case "notEquals":
      return FilterOperatorBackend.NotEquals;
    case "greaterThan":
      return FilterOperatorBackend.GreaterThan;
    case "greaterThanOrEqual":
      return FilterOperatorBackend.GreaterThanOrEqual;
    case "lessThan":
      return FilterOperatorBackend.LessThan;
    case "lessThanOrEqual":
      return FilterOperatorBackend.LessThanOrEqual;
    case "startsWith":
      return FilterOperatorBackend.StartsWith;
    case "endsWith":
      return FilterOperatorBackend.EndsWith;
    case "notStartsWith":
      return FilterOperatorBackend.NotStartsWith;
    case "betweenInclusive":
      return FilterOperatorBackend.BetweenInclusive;
    case "inList":
      return FilterOperatorBackend.Equals;
    default: {
      // ts check for exhaustiveness
      const _exhaustiveCheck: never = op;
      return FilterOperatorBackend.Equals;
    }
  }
};

export const groupFilters = (filters: QueryFilter[]): FilterBody => {
  const filterGroups: Record<string, FilterGroup[]> = {};

  for (const filter of filters) {
    filterGroups[filter.field] ??= [];

    if (typeof filter.value === "boolean") {
      // you can't have more than one filter on a boolean
      filterGroups[filter.field] = [
        {
          operator: getFilterBodyOperatorEnum(filter.operator),
          value: filter.value,
        },
      ];
      continue;
    }

    filterGroups[filter.field].push(
      ...filter.value.map((y) => ({
        operator: getFilterBodyOperatorEnum(filter.operator),
        value: y,
      }))
    );
  }

  return {
    filterGroups,
  };
};

const getDefaultFilter = (
  defaultFilterKey: string,
  defaultFilterValue: string
): QueryFilter => {
  return {
    field: defaultFilterKey,
    operator: "equals",
    value: [defaultFilterValue],
  };
};
export const getFilters = ({
  filters,
  defaultFilterKey,
  defaultFilterValue,
}: {
  filters: FilterSchema[];
  defaultFilterKey: string;
  defaultFilterValue: string;
}): FilterConfigResponse => {
  const result: FilterConfig[] = filters.map(
    (filter) =>
      ({
        key: filter.key,
        label: filter.text,
        availableOperators: filter.operator
          ? [filter.operator]
          : typeToValidOperators[filter.type],
        icon: filterIcon.get(filter.key) ?? "mdi-filter",
        type: filter.type,
        values: filter.values ?? [],
      }) satisfies FilterConfig
  );

  const defaultFilter =
    defaultFilterKey.length > 0
      ? getDefaultFilter(defaultFilterKey, defaultFilterValue)
      : null;
  return {
    filters: result,
    defaultFilterKey,
    defaultFilterValue,
    defaultFilter,
  };
};

export type FilterValueListOption<T> = {
  value: T;
  label: string;
  icon?: string;
};

const flagKeys = [
  "suspectedUndeclaredDgFlag",
  "suspectedMisdeclaredDgFlag",
  "unusualBehaviourFlag",
  "documentAuthenticityFlag",
];
export function mapFilterConfigFlagFilters(
  filterConfig: FilterConfigResponse
): FilterConfigResponse {
  const flags: string[] = [];
  for (const filter of filterConfig.filters) {
    if (flagKeys.includes(filter.key)) flags.push(filter.key);
  }
  if (flags.length === 0) return filterConfig;

  filterConfig.filters = filterConfig.filters
    .filter((filter) => !flagKeys.includes(filter.key))
    .concat({
      key: "dgFlag",
      label: "Dangerous Goods Flags",
      availableOperators: typeToValidOperators.flag,
      icon: "mdi-flag-checkered",
      type: "flag",
      values: flags,
    });
  return filterConfig;
}

const flagValuesSchema = z.enum([
  "suspectedUndeclaredDgFlag",
  "suspectedMisdeclaredDgFlag",
  "unusualBehaviourFlag",
  "documentAuthenticityFlag",
]);
const filterFlagSchema = z.object({
  field: z.literal("dgFlag"),
  value: z.array(flagValuesSchema).min(1),
  operator: z.enum(["flagHas", "flagNotHas"]),
});
export function mapFrontendFlagFilters(
  filters: FrontendQueryFilter[]
): QueryFilter[] {
  let flagFilters: QueryFilter[] = [];
  for (const filter of filters) {
    const flag = filterFlagSchema.safeParse(filter);
    if (!flag.success) continue;
    flagFilters = flagFilters.concat(
      flag.data.value.map((f) => ({
        field: f,
        value: [flag.data.operator === "flagHas"],
        operator: "equals",
      }))
    );
  }

  if (flagFilters.length === 0) return filters as QueryFilter[];

  return filters
    .filter(
      (filter): filter is QueryFilter =>
        !["flagHas", "flagNotHas"].includes(filter.operator)
    )
    .concat(flagFilters);
}
